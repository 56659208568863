import React, { useState, useEffect } from 'react';
import { graphql, Link, navigate } from 'gatsby';
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../theme';
import Layout from '../components/layout/layout';
import Markdown from '../components/markdown';
import { LoadingOverlay } from '../components/loading-overlay/loading-overlay';
import useFormGenerator from '../hooks/useFormGenerator';

import { submitClaim, getClaimantData } from '../services/claims.service';
import useAuth from '../hooks/useAuthentication';
import {
  useClaimStatus,
  usePaymentMethod,
  useSnackbar,
  useUserCredentials,
} from '../store';
import { SnackBar } from '../components/snackbar';
import { claimTypeValues } from '../constants';
import { partTwoBlock2 } from '../../markdownSources';
import logAnalyticsEvent from '../utils/log-analytics-event';

const ClaimForm = ({ data }) => {
  useEffect(() => {
    logAnalyticsEvent('claim_form_page_visit', {
      caseId: data?.template?.caseID,
    });
  }, [data?.template?.caseID]);

  const { caseName, caseID, authentication } = data?.template;

  const { openSnackbar } = useSnackbar();
  const { authenticated } = useAuth();
  const { setClaimID } = useClaimStatus();
  const { setShouldUsePaymentMethod } = usePaymentMethod();

  const isLG = useMediaQuery(theme.breakpoints.up('lg'));

  const [loading, setLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');

  const [
    partOneGenerator,
    submitHandler,
    updateInitialValues,
    partTwoGenerator,
    partThreeGenerator,
  ] = useFormGenerator({
    subscriptionData: {
      subscriptionType,
      subscriptionStatus,
      subscriptionStatusAndType: `${subscriptionStatus} ${subscriptionType}`,
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const request = {
          caseID,
          caseName,
          claimSearch: {
            orderDate: new Date().toISOString(),
            billingFirstName: values.firstName,
            billingLastName: values.lastName,
            billingStreet: values.address,
            billingStreet2: values.address2,
            billingCity: values.city,
            billingState: values.state,
            billingZip: values.zipCode,
            phone: values.phone,
            email: values.email,
            claimItemType: claimTypeValues.directNotice,
            referrer: document.referrer,
            additionalData: {
              optionSelected: values.optionSelected,
              acceptedPrivacy: values.acceptPrivacy,
              date: dayjs(values.date).format('MM/DD/YYYY'),
              phone2: values.phone2,
              claimantFullName: values.claimantFullName,
              certified: values.certified,
            },
          },
        };

        const data = await submitClaim({
          job: request,
        });

        const {
          data: { orderID },
        } = data;

        logAnalyticsEvent('claim_form_submit_success', {
          claimId: orderID,
          caseId: caseID,
        });

        if (values.optionSelected === '1') {
          setShouldUsePaymentMethod(true);
        }
        setClaimID(orderID);
        navigate('/claim-check');
        return;
      } catch (e) {
        logAnalyticsEvent('claim_form_submit_error', { caseId: caseID });
        const errorMessage =
          'Experiencing an unexpected error. Please try reloading the page.';
        openSnackbar(errorMessage, 'error');
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const getClaimant = async () => {
      try {
        setLoading(true);

        const result = await getClaimantData(caseID);
        const metadata = JSON.parse(result.data.claimant.metadata);
        setSubscriptionStatus(metadata.subscriptionStatus);
        setSubscriptionType(metadata.subscriptionType);

        updateInitialValues(result.data.claimant);
      } catch (err) {
        openSnackbar(
          'Experiencing an unexpected error. Please try reloading the page.',
          'error'
        );
      } finally {
        setLoading(false);
      }
    };

    if (authenticated && authentication?.loginForm) {
      getClaimant();
    }
  }, [authenticated]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <LoadingOverlay open={loading} />
      <Layout>
        <SnackBar>
          <form onSubmit={submitHandler}>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontWeight: 700, fontSize: '48px' }}>
                Submit a Claim
              </Typography>
            </Box>
            <Box sx={{ mb: 8 }}>
              <Breadcrumbs separator="→" aria-label="breadcrumb">
                {[
                  <Link key="1" to="/">
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '16px',
                          md: '32px',
                          color: 'neutral.500',
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      Home
                    </Typography>
                  </Link>,
                  Array.isArray(data.template.instructions) &&
                    data.template.instructions.length > 0 && (
                      <Link key="2" to="/claim-instructions">
                        <Typography
                          sx={{
                            fontSize: {
                              xs: '16px',
                              md: '32px',
                              color: 'neutral.500',
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          Claim Instructions
                        </Typography>
                      </Link>
                    ),
                  <Typography
                    key="3"
                    sx={{ fontSize: { xs: '16px', md: '32px' } }}
                  >
                    Claim Form
                  </Typography>,
                ]}
              </Breadcrumbs>
            </Box>

            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '24px',
                    textTransform: 'uppercase',
                  }}
                >
                  Part One: Claimant Information
                </Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container>
                  <Grid item sx={{ mb: 3 }}>
                    <Typography variant="body">
                      Provide your name and contact information below. It is
                      your responsibility to notify the Settlement Administrator
                      of any changes to your contact information after the
                      submission of your Claim Form.
                    </Typography>
                  </Grid>
                  {partOneGenerator()}
                </Grid>
              </CardContent>
            </Card>

            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '24px',
                    textTransform: 'uppercase',
                  }}
                >
                  Part Two: Subscription Information
                </Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container>
                  <Grid item>
                    <p>
                      <Typography variant="body">
                        To quality for a settlement benefit, you must have been
                        a FloSports subscriber who, from August 29, 2018 through
                        June 15, 2023, enrolled in an automatically renewing
                        FloSports subscription using a California, New York,
                        North Carolina, Oregon, Florida, Illinois, Washington
                        D.C., North Dakota, Virginia, Hawaii or Vermont billing
                        address and paid fee(s) in connection with your
                        subscription.
                      </Typography>
                    </p>
                  </Grid>
                  {partTwoGenerator()}
                  <Grid item sx={{ mb: 3 }}>
                    <Typography variant="h6">
                      The cash payments set out herein represent the maximum
                      that you can receive under the settlement. The actual cash
                      paid may be reduced depending on the aggregate total of
                      claims submitted by all class members.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      <sup>1</sup>The electronic vouchers should be available
                      within ninety (90) days after the settlement becomes
                      final. The vouchers will not expire. Exceptions apply.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '24px',
                    textTransform: 'uppercase',
                  }}
                >
                  Part Three: Attestation under penalty of perjury
                </Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container>{partThreeGenerator()}</Grid>
              </CardContent>
            </Card>
            <Box sx={{ mb: 2 }}>
              <Button
                type="submit"
                fullWidth={!isLG}
                variant="contained"
                sx={{
                  my: 2,
                  color: 'background.paper',
                  px: { md: '44px' },
                  py: 2,
                  fontSize: '15px',
                  fontWeight: 400,
                }}
                disableElevation
              >
                Submit Claim
              </Button>
            </Box>
          </form>
        </SnackBar>
      </Layout>
    </LocalizationProvider>
  );
};

export default ClaimForm;

export const Head = ({ data }) => (
  <title>ClaimForm | {data.template.caseName}</title>
);

export const query = graphql`
  query FormPage($id: StringQueryOperatorInput = {}) {
    caseConfiguration(id: $id) {
      billingCity {
        editable
        nameToShow
      }
      billingFirstName {
        editable
        nameToShow
      }
      billingLastName {
        editable
        nameToShow
      }
      billingState {
        editable
        nameToShow
      }
      billingStreet {
        editable
        nameToShow
      }
      billingStreet2 {
        editable
        nameToShow
      }
      billingZip {
        editable
        nameToShow
      }
      email {
        editable
        nameToShow
      }
      phone {
        editable
        nameToShow
      }
    }
    template(id: $id) {
      caseID
      caseName
      claimInfo {
        type
        wrapper
        inputs
        text
        group
        options {
          id
          description
          value
        }
      }
      instructions {
        type
      }
      authentication {
        loginForm
        idFieldName
        secretFieldName
      }
    }
  }
`;
